<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="10">
              <el-form-item prop="title" label="标题">
                <el-input v-model="queryParams.title" placeholder="请输入标题" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item label="发布日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">{{showSearch ? '隐藏搜索' : '显示搜索'}}</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'sort', order: 'ascending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="title" label="标题"/>

        <el-table-column label="封面">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 30px;"
                :src="scope.row.cover"
                :preview-src-list="[scope.row.cover]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="watchCount" label="观看次数" sortable="custom"/>

        <el-table-column prop="sort" label="排序" sortable="custom"/>

        <el-table-column prop="pubdate" label="发布日期" sortable="custom"/>

        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <!--<el-button type="text" @click="handleView(scope.row)">查 看</el-button>-->
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button type="text" @click="handleManageDetail(scope.row)">配置视频</el-button>
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" top="100" width="1000px">
          <div style="width: 100%">
            <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
              <el-form-item label="" prop="id">
                <el-input v-show="false" v-model="formData.id"></el-input>
              </el-form-item>
              <el-row gutter="20">
                <el-col :span="12">
                  <el-form-item label="标题" prop="title">
                    <el-input v-model="formData.title" placeholder="请输入标题" clearable/>
                  </el-form-item>

                  <el-form-item label="简介" prop="introduction">
                    <el-input v-model="formData.introduction" type="textarea" :rows="6" placeholder="请输入简介" show-word-limit maxlength="300" clearable/>
                  </el-form-item>

                  <el-form-item label="观看次数" prop="watchCount">
                    <el-input v-model="formData.watchCount" min="0" type="number" placeholder="请输入观看次数"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="发布日期" prop="pubdate">
                    <el-date-picker
                        style="width: 100%"
                        v-model="formData.pubdate"
                        type="date"
                        placeholder="选择发布日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :default-time="formData.pubdate">
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="排序" prop="sort">
                    <el-input v-model="formData.sort" min="1" max="99999" type="number" placeholder="请输入排序"/>
                  </el-form-item>

                  <!--让表单重置生效-->
                  <el-form-item label="封面" prop="cover" v-show="false" ></el-form-item>

                  <el-form-item label="封面" prop="coverFileId">
                    <el-input type="number" v-show="false" v-model="formData.coverFileId"></el-input>
                    <el-upload
                        class="avatar-uploader"
                        :headers="{Authorization: token}"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :multiple="false"
                        :on-success="handleImageSuccess"
                        :before-upload="beforeImageUpload">
                      <img v-if="formData.cover" :src="formData.cover" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <el-button v-if="formData.cover" size="small" type="danger" @click="handleImageDelete">删除封面</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import store from "@/store";

export default {
  name: "index",
  components: {CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        title: '',
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        title: '',
        sort: 99,
        introduction: '',
        cover: '',
        coverFileId: null,
        watchCount: 0,
        pubdate: ''
      },

      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        pubdate: [
          {required: true, message: '请选择发布日期', trigger: 'blur'},
        ],
        watchCount: [
          {required: true, message: '请输入观看次数', trigger: 'blur'},
          {type: 'number', message: '观看次数必须为数字', trigger: 'blur'}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
      previewList: [],
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.video.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    handleView() {

    },

    handleManageDetail(row) {
      this.$router.push({
        name: 'video-detail-index',
        params: {id: row.id}
      })
    },

    handleUpdate(row) {
      this.$api.video.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除 “ <b class="color-danger">${row.title}</b> ”？`
      }).then(() => {
        this.$api.video.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.video.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.video.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.coverFileId = res.data.id
      this.formData.cover = res.data.url;
    },

    handleImageDelete() {
      this.formData.cover = ''
      this.formData.coverFileId = null
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 180px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 320px;
  height: 180px;
  display: block;
}
</style>
